import {Component, OnInit} from '@angular/core';
import {API} from '../../api';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Router} from '@angular/router';
import {PickerService} from 'ng-zorro-antd-mobile';

@Component({
  selector: 'app-history-list',
  templateUrl: './history-list.component.html',
  styleUrls: ['./history-list.component.less']
})
export class HistoryListComponent implements OnInit {
  historyListData = [
    {value: 0, costsStartTime: '2020-01-10', costsEndTime: '2021-01-10', totalPrice: '6960.2'},
    {value: 0, costsStartTime: '2020-01-10', costsEndTime: '2021-01-10', totalPrice: '6960.2'},
  ];
  id;
  tabTwoIndex = 0;

  constructor(
    private api: API,
    public http: HttpClient,
    private router: Router,
    private picker: PickerService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.getHistoryList();
  }

  /**
   * 返回
   */
  goBack(): void {
    this.router.navigate(['/list']);
  }

  /**
   * 获取历史缴费列表
   */
  getHistoryList(): any {
    this.historyListData = [];
    let apiPar;
    if (this.tabTwoIndex === 0) {
      apiPar = this.api.getAllPaidBillByPayer;
    } else if (this.tabTwoIndex === 1) {
      apiPar = this.api.HISTORY_LIST;
    } else {
      apiPar = this.api.getPaidBillByPayer;
    }
    this.http.get(apiPar).subscribe(e => {
      const data = (e as any).data;
      data.forEach(item => {
        if (this.tabTwoIndex === 0) {
          item.startTime ? item.startTime = item.startTime.slice(0, 10) : null;
          item.endTime ? item.endTime = item.endTime.slice(0, 10) : null;
        } else if (this.tabTwoIndex === 1) {
          item.costsEndTime = item.costsEndTime.slice(0, 10);
          item.costsStartTime = item.costsStartTime.slice(0, 10);
        } else {
          item.meterReadTime = item.meterReadTime.slice(0, 10);
        }
        this.historyListData.push(item);
      });
    });

  }

  onClick(ev, billType): any {
    console.log('click', ev);
    this.id = ev.id;
    // 跳转历史缴费详情
    if (this.tabTwoIndex === 0) {
      if (ev.billType === 1) {
        this.router.navigate(['/detail'], {
          queryParams: {
            id: ev.id,
            bType: billType,
          }
        });
      } else {
        this.router.navigate(['/water-detail'], {
          queryParams: {
            id: ev.id,
            bType: billType,
            type: ev.billType,
          }
        });
      }
    } else if (this.tabTwoIndex === 1) {
      this.router.navigate(['/detail'], {
        queryParams: {
          id: ev.id,
          bType: billType,
        }
      });
    } else {
      this.router.navigate(['/water-detail'], {
        queryParams: {
          id: ev.id,
          bType: billType,
        }
      });
    }
  }


  onPageChange(ev: number): void {
    this.tabTwoIndex = ev;
    this.getHistoryList();
  }


}
