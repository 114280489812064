function loadJSON(filePath) {
  return loadTextFileAjaxSync(filePath, 'application/json');
}

function loadTextFileAjaxSync(filePath, mimeType) {
  const xmlhttp = new XMLHttpRequest();
  xmlhttp.open('GET', filePath, false);
  if (mimeType != null) {
    if (xmlhttp.overrideMimeType) {
      xmlhttp.overrideMimeType(mimeType);
    }
  }
  xmlhttp.send();
  if (xmlhttp.status === 200) {
    return xmlhttp.responseText;
  } else {
    return null;
  }
}


export const environment = {
  host: ``,
  imageHost: ``,
  loginhost: ``,
  wshost: ``,
  iconfont: ``,
  webHost: ``,
  path: '',
  production: false,
  useHash: true,
  hmr: false, ...JSON.parse(loadJSON('/assets/config.json')),
};
