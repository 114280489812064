import {Injectable, Injector} from '@angular/core';
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponseBase,
  HttpResponse,
  HttpErrorResponse, HttpClientModule
} from '@angular/common/http';
import {Observable, of, throwError} from 'rxjs';
import {environment} from '../environments/environment';
import {catchError, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {API} from './api';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  constructor(
    private injector: Injector,
    private $router: Router,
    private api: API,
    private route: ActivatedRoute,
  ) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // 统一加上服务端前缀
    let url = req.url;
    if (!url.startsWith('https://') && !url.startsWith('http://') && !url.startsWith('/assets/') && !url.startsWith('assets/')) {
      url = environment.host + url;
    }
    // 判断是否有taken
    const token = window.localStorage.getItem('official_token');
    const token1 = 'eyJhbGciOiJSUzI1NiJ9.eyJhZ3JwIjoibzZENUU1dzN1TjZmUWJhOTJ4ZU8zNkNMN2w5dyIsInVpZCI6MjQwMiwiaWF0IjoxNjE0MDU0ODg5LCJleHAiOjE2MTQ5MTg4ODl9.GPH129PTT2ESJT9-WuwOpX6qezXj_T7jzK9LtSupAbyv-GmeBfGFwnkQtliWUPQGysqVkXrIFfOV6y3vusPkSqTK7NqjIV8kfpOLibytPUd6zAQnjhYzFdSLo83wN-1360z9NF_3RHgTKH7YkQ17x8di4Y8r2y_Fx1-_il-qjS9iBdrXDNdKJFiI73JB7-Rqy_lSYUztTtLIPz78wtcajxstIKQOn0VcnbwClng8P_Y7Ddvx0ssJu4Sw37cu9fVQY362TbXHwY0jgIlubnHmpIM-GPkyEpWbm-ubR0Ri0zcslgyvP2N2qU69a9Q8rDMdP15F3xnYo8HPanwVigu71A';
    if (token && token.length > 0) {
      const newReq = req.clone({
        url,
        headers: req.headers.set('Authorization', 'Bearer ' + token)
      });
      return next.handle(newReq).pipe(
        mergeMap((event: any) => {
          // console.log('ev: ', event)
          // 允许统一对请求错误处理
          if (event instanceof HttpResponseBase) {
            return this.handleData(event);
          }
          // 若一切都正常，则后续操作
          // console.log('ev ok: ')
          return of(event);
        }),
        catchError((err: HttpErrorResponse) => this.handleError(err)),
      );
    } else {
      // 没有token跳到登录
      this.$router.navigate(['/first']).then();
    }
  }


  private handleError(ev: HttpErrorResponse): Observable<any> {
    console.log('ev err: ', ev);
    if (ev.url.startsWith('http://localhost')) {
      return throwError(ev);
    }
    switch (ev.status) {
      case 401:
        alert('未登录或登录已过期，请重新登录。');
        // 清空token
        localStorage.clear();
        this.$router.navigate(['/first']).then();
        break;
      case 400:
        alert(ev.error && ev.error.message ? ev.error.message : `参数提交错误`);
        break;
      case 403:
        alert('权限不足。');
        break;
      case 500:
        alert('服务器出现了问题，请联系管理员。');
        break;
      default:
        alert('网络连接异常。');
        break;
    }
    return throwError(ev);
  }

  private handleData(ev: HttpResponseBase): Observable<any> {
    // console.log('ev data: ')
    // 可能会因为 `throw` 导出无法执行 `_HttpClient` 的 `end()` 操作
    // if (ev.status > 0) {
    //   this.injector.get(_HttpClient).end();
    // }
    // const tk = ev.headers.get('Authorization');
    // if (tk && tk.split('.').length === 3) {
    //   const ak = this.token.get();
    //   ak.token = tk;
    //   this.token.set(ak);
    // }
    return of(ev);
  }
}


