import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {API} from '../../api';
import {HttpClient} from '@angular/common/http';
import {PickerService} from 'ng-zorro-antd-mobile';

declare var WeixinJSBridge;
declare var document: any;

@Component({
  selector: 'app-recharge',
  templateUrl: './recharge.component.html',
  styleUrls: ['./recharge.component.less']
})
export class RechargeComponent implements OnInit {
  pageTitle = '预存充值';
  name = '';
  amountValue;
  houseId;

  constructor(
    private $activatedRoute: ActivatedRoute,
    private api: API,
    public http: HttpClient,
    private router: Router,
    private picker: PickerService,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.$activatedRoute.queryParams.subscribe(queryParams => {
      this.name = queryParams.houseName;
      this.houseId = queryParams.houseId;
    });
  }


  goBack(): void {
    window.history.go(-1);
  }

  onAmountChange(event): void {
    if (event && !/^\d+(\.\d{0,2})?$/.test(event)) {
      alert('请输入正确的金额');
    } else {
      this.amountValue = event;
    }
  }

  rechargePay(): void {
    this.http.get(this.api.pubRechargeAmount(this.houseId, this.amountValue)).subscribe(e => {
      const data = (e as any).data;
      if (typeof WeixinJSBridge === 'undefined') {
        if (document.addEventListener) {
          document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(data), false);
        } else if (document.attachEvent) {
          document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(data));
          document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(data));
        }
      } else {
        this.onBridgeReady(data);
      }
    });

  }


  onBridgeReady(data): any {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
        'appId': data.appId,     // 公众号名称，由商户传入
        'timeStamp': data.timeStamp,         // 时间戳，自1970年以来的秒数
        'nonceStr': data.nonceStr, // 随机串
        'package': data.package,
        'signType': data.signType,        // 微信签名方式：
        'paySign': data.paySign, // 微信签名
      }, (res) => {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          alert('微信支付成功!');
        } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
          alert('用户取消支付!');
        } else {
          alert('支付失败!');
        }
      });
  }


}
