<div id="billList" class="bill-list">
  <!--  <Navbar [leftContent]="'<'" (onLeftClick)="onLeftClick()">-->
  <!--    {{pageTitle}}-->
  <!--  </Navbar>-->

  <div class="nav-bar">
    {{pageTitle}}
    <span class="nav-dump" (click)="goBack()"><img style="width: 20px;" src="assets/back.png" alt="返回"></span>
    <div *ngIf="index!==0" style="position: absolute;top:10px;right: 15px;" Button [type]="'primary'" [inline]="true"
         [size]="'small'" (click)="onDumpHistory()">
      缴费记录
    </div>

    <div *ngIf="index===0" style="position: absolute;top:10px;right: 15px;" Button [type]="'primary'" [inline]="true"
         [size]="'small'" (click)="onDumpRecharge()">
      预存充值
    </div>

    <div class="up">
      <span class="house">房产信息：</span> <span class="house-content">{{ name }}
      <div class="button-position" Button [type]="'primary'" [inline]="true" [size]="'small'" (click)="showPicker()">切换
      </div>
    </span>
    </div>

    <div id="list" class="list">
      <Tabs
        [page]="2"
        [useOnPan]="true"
        [swipeable]="false"
        [activeTab]="index"
        [tabBarActiveTextColor]="'#1890ff'"
        (onTabClick)="onTabClick($event)">
        <TabPane [title]="titleTemplate">
          <ng-template #titleTemplate>
            <div>未缴账单</div>
          </ng-template>

          <div class="tab-two">
            <div [class]="tabTwoIndex==0?'tab-two-block-active':'tab-two-block'" style="width: 50%"
                 (click)="onPageChange(0)">物业费
            </div>
            <div [class]="tabTwoIndex==1?'tab-two-block-active':'tab-two-block'" style="width: 50%"
                 (click)="onPageChange(1)">水费
            </div>
          </div>

          <List>
            <div class="list-box" *ngFor="let i of unpaidListData">
              <div class="c-box">
                <CheckboxItem style="height: 65px;"
                              [(ngModel)]="i.checked"
                              (onChange)="onChange(i)">
                </CheckboxItem>
              </div>
              <div class="c-right">
                <ListItem *ngIf="tabTwoIndex==0" style="height: 65px;" [extra]="'￥'+i.totalPrice" [arrow]="'horizontal'"
                          (onClick)="onClick(i,'unpaid')">
                  <div class="wyf">物业费</div>
                  <div class="wye-date">{{i.costsStartTime}} 到 {{i.costsEndTime}}</div>
                </ListItem>

                <ListItem *ngIf="tabTwoIndex==1" style="height: 65px;" [extra]="'￥'+i.unpaidAmount" [arrow]="'horizontal'"
                          (onClick)="onClick(i,'unpaid')">
                  <div class="wyf">水费</div>
                  <div class="wye-date">{{i.meterReadTime}}</div>
                </ListItem>
              </div>

              <div class="b-space"></div>
            </div>
          </List>
        </TabPane>
        <TabPane [title]="titleTemplate1">
          <ng-template #titleTemplate1>
            <div>历史账单</div>
          </ng-template>
          <div class="tab-two">
            <div [class]="tabTwoIndex==0?'tab-two-block-active':'tab-two-block'" style="width: 50%"
                 (click)="onPageChange(0)">全部
            </div>
            <div [class]="tabTwoIndex==1?'tab-two-block-active':'tab-two-block'" style="width: 50%"
                 (click)="onPageChange(1)">物业费
            </div>
            <div [class]="tabTwoIndex==2?'tab-two-block-active':'tab-two-block'" style="width: 50%"
                 (click)="onPageChange(2)">水费
            </div>
          </div>
          <List>
            <div class="list-box" *ngFor="let i of historyListData">
              <ListItem *ngIf="tabTwoIndex==0" style="height: 65px;" [extra]="'￥'+i.amount" [arrow]="'horizontal'"
                        (onClick)="onClick(i,'history')">
                <div *ngIf="i.billType == 1" class="wyf">物业费</div>
                <div *ngIf="i.billType == 1" class="wye-date">{{i.startTime}} 到 {{i.endTime}}</div>

                <div *ngIf="i.billType == 2" class="wyf">水费</div>
                <div *ngIf="i.billType == 2" class="wye-date">{{i.startTime}}</div>
              </ListItem>

              <ListItem *ngIf="tabTwoIndex==1" style="height: 65px;" [extra]="'￥'+i.totalPrice" [arrow]="'horizontal'"
                        (onClick)="onClick(i,'history')">
                <div class="wyf">物业费</div>
                <div class="wye-date">{{i.costsStartTime}} 到 {{i.costsEndTime}}</div>
              </ListItem>

              <ListItem *ngIf="tabTwoIndex==2" style="height: 65px;" [extra]="'￥'+i.billAmount" [arrow]="'horizontal'"
                        (onClick)="onClick(i,'history')">
                <div class="wyf">水费</div>
                <div class="wye-date">{{i.meterReadTime}}</div>
              </ListItem>

              <div class="b-space"></div>
            </div>
          </List>
        </TabPane>
      </Tabs>
    </div>


    <div class="footer-box" *ngIf="index===0">
      <div class="footer">
        <CheckboxItem *ngFor="let i of allCheckData"
                      [name]="i.name"
                      [value]="i.value"
                      [(ngModel)]="i.checked"
                      (onChange)="onAllChange($event)">
          <span class="s-common">全选</span>({{costNum}})
          <span class="zj">
            <span class="s-common">合计：</span>￥{{costCount}}
          </span>
          <span class="zf" (click)="onPay()">支付 ({{costNum}})</span>
        </CheckboxItem>

      </div>
    </div>


  </div>





