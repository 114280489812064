import { NgModule } from '@angular/core';
import {Routes, RouterModule, InitialNavigation} from '@angular/router';
import {PublicPageOneComponent} from './estate/public-page-one/public-page-one.component';
import {BillListComponent} from './estate/bill-list/bill-list.component';
import {PublicPageTwoComponent} from './estate/public-page-two/public-page-two.component';
import {HistoryDetailComponent} from './estate/history-detail/history-detail.component';
import {environment} from '../environments/environment';
import {HistoryListComponent} from './estate/history-list/history-list.component';
import {RechargeComponent} from './estate/recharge/recharge.component';
import {WaterDetailComponent} from './estate/water-detail/water-detail.component';

const routes: Routes = [
  { path: 'first', component: PublicPageOneComponent },
  { path: '', redirectTo: 'first', pathMatch: 'full' },
  // 认证登录
  { path: 'auth', component: PublicPageTwoComponent },
  // 所有缴费列表
  { path: 'list', component: BillListComponent },
  // 历史缴费列表
  { path: 'history-list', component: HistoryListComponent },
  { path: 'detail', component: HistoryDetailComponent },
  // 预存充值
  { path: 'recharge', component: RechargeComponent },
  // 水费详情
  { path: 'water-detail', component:  WaterDetailComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: environment.useHash,
    // NOTICE: If you use `reuse-tab` component and turn on keepingScroll you can set to `disabled`
    // Pls refer to https://ng-alain.com/components/reuse-tab
    scrollPositionRestoration: 'top',
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
