import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';

import {AppComponent} from './app.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {FormsModule} from '@angular/forms';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {NgZorroAntdMobileModule} from 'ng-zorro-antd-mobile';
import {BillListComponent} from './estate/bill-list/bill-list.component';
import {HistoryDetailComponent} from './estate/history-detail/history-detail.component';
import {PublicPageOneComponent} from './estate/public-page-one/public-page-one.component';
import {PublicPageTwoComponent} from './estate/public-page-two/public-page-two.component';
import {AppRoutingModule} from './app-routing.module';
import {RouterModule} from '@angular/router';
import {DefaultInterceptor} from './default.interceptor';
import { HistoryListComponent } from './estate/history-list/history-list.component';
import { RechargeComponent } from './estate/recharge/recharge.component';
import { WaterDetailComponent } from './estate/water-detail/water-detail.component';

const INTERCEPTOR_PROVIDES = [
  {provide: HTTP_INTERCEPTORS, useClass: DefaultInterceptor, multi: true},
];

@NgModule({
  declarations: [
    AppComponent,
    BillListComponent,
    HistoryDetailComponent,
    PublicPageOneComponent,
    PublicPageTwoComponent,
    HistoryListComponent,
    RechargeComponent,
    WaterDetailComponent
  ],
  imports: [
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgZorroAntdMobileModule,
    RouterModule.forRoot([]),
  ],
  providers: [INTERCEPTOR_PROVIDES],
  bootstrap: [AppComponent],
  entryComponents: [],
})
export class AppModule {
}
