<div id="billList" class="bill-list">

  <div class="nav-bar">
    {{pageTitle}}
    <span class="nav-dump" (click)="goBack()"><img style="width: 20px;" src="assets/back.png" alt="返回"></span>

    <div class="up">
      <span class="house">房产信息：</span> <span class="house-content">{{ name }}</span>
    </div>

    <div class="up">
      <span class="house">金额：</span>
      <input class="house-content" nz-input placeholder="请输入金额" style=" width: 70%;height: 42px;border: none;padding-left: 24px;color:#5c5a5a;"
             [(ngModel)]="amountValue"
             (ngModelChange)="onAmountChange($event)"/>
    </div>

    <div class='pay-button' (click)="rechargePay()">立即付款</div>
  </div>


</div>

