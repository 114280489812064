import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {API} from '../../api';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';

declare var WeixinJSBridge;
declare var document: any;

@Component({
  selector: 'app-history-detail',
  templateUrl: './history-detail.component.html',
  styleUrls: ['./history-detail.component.less']
})
export class HistoryDetailComponent implements OnInit {
  // pageTitle = '未缴账单';
  // 页面类型
  pageType;
  // 房间id
  houseId;
  // 账单id
  id;
  // 匹配的对应账单详情
  // billInfo;
  billInfo = {
    houseInfo: '',
    totalPrice: '',
    price: '',
    houseArea: '',
    costsStartTime: '',
    costsEndTime: '',
    discountAmount: '',
    payDate: ''
  };
  billType;

  constructor(
    private api: API,
    public http: HttpClient,
    private router: Router,
    private $activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    const uaHeight = window.outerHeight - (window.outerHeight - window.innerHeight);
    window.document.getElementById('detailPage').style.height = uaHeight + 'px';
    this.$activatedRoute.queryParams.subscribe(queryParams => {
      this.id = Number(queryParams.id);
      // this.pageType = queryParams.type;
      this.billType = queryParams.bType;
      this.getCostsDetail();
    });
  }

  /**
   * 获取账单详情
   */
  getCostsDetail(): any {
    this.http.get(this.api.PAY_COSTS_DETAIL, {params: {id: this.id}}).subscribe(e => {
      const data = (e as any).data;
      data.costsEndTime = data.costsEndTime.slice(0, 10);
      data.costsStartTime = data.costsStartTime.slice(0, 10);
      data.discountAmount === null ? data.discountAmount = 0 : '';
      data.houseInfo = data.communityName + data.houseName;
      this.pageType = data.payStatus;
      this.billInfo = data;
    });
  }

  goInitBack(): void {
    this.router.navigate(['/list']);
  }

  /**
   * 返回
   */
  goBack(): void {
    if (this.billType && this.billType === 'record') {
      window.history.go(-1);
    } else {
      let billType;
      this.pageType !== 1 ? billType = 'unpaid' : billType = 'history';
      this.router.navigate(['/list'], {
        queryParams: {
          tab: billType,
        }
      });
    }
  }

  /**
   * 支付请求
   */
  onPay(): any {
    this.http.get(this.api.GET_PAY_INFO(this.id)).subscribe(e => {
      const data = (e as any).data;
      if (data.amount === 0) {
        this.goBack();
      } else {
        if (typeof WeixinJSBridge === 'undefined') {
          if (document.addEventListener) {
            document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(data), false);
          } else if (document.attachEvent) {
            document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(data));
            document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(data));
          }
        } else {
          this.onBridgeReady(data);
        }
      }
    });
  }

  onBridgeReady(data): any {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
        'appId': data.appId,     // 公众号名称，由商户传入
        'timeStamp': data.timeStamp,         // 时间戳，自1970年以来的秒数
        'nonceStr': data.nonceStr, // 随机串
        'package': data.package,
        'signType': data.signType,        // 微信签名方式：
        'paySign': data.paySign, // 微信签名
      }, (res) => {
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          alert('微信支付成功!');
          this.http.get(this.api.PAY_SUCCESS(this.id)).subscribe(e => {
            window.location.href = environment.host + '/list';
          });
        } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
          alert('用户取消支付!');
        } else {
          alert('支付失败!');
        }
      });
  }


}
