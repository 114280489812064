import {Component, OnInit} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {API} from '../../api';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-public-page-one',
  templateUrl: './public-page-one.component.html',
  styleUrls: ['./public-page-one.component.less']
})
export class PublicPageOneComponent implements OnInit {
  districtId;
  id;

  constructor(
    private api: API,
    public http: HttpClientModule,
    private router: Router,
    private route: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    const result = this.is_weixin();
    // 如果不是微信内置浏览器，就动态跳转到以下页面
    if (!result) {
      // 不是就跳转提示页面
      window.location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1039be4c8e5e259b&redirect_uri=' +
        '&response_type=code&scope=snsapi_base&state=hyxt#wechat_redirect`;
      return;
    }
    // 判断是否有taken
    const token = window.localStorage.getItem('official_token');
    if (token && token.length > 0) {
      this.router.navigate(['/list', {communityId: this.districtId, id: this.id, type: 'unpaid'}],
        {relativeTo: this.route});
    } else {
      const redirectUrl = encodeURIComponent(this.api.PUB_AUTH + '');
      const wxUrl = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1039be4c8e5e259b&redirect_uri=' + redirectUrl +
      '&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect';
      window.location.href = wxUrl;
    }
  }

  is_weixin(): boolean {
    // 判断浏览器的类型
    const ua = navigator.userAgent.toLowerCase();
    return ua.indexOf('micromessenger') !== -1;
  }


}
