<div id="detailPage" class="detail">
  <div class="nav-bar">账单记录
    <!--    {{pageTitle}}-->
    <span class="nav-dump" (click)="goInitBack()"><img  style="width: 20px;" src="assets/back.png" alt="返回"></span>
  </div>

  <div class="up">
    <span class="house">房产信息：</span> <span
    class="house-content">{{billInfo.houseInfo}} </span>
  </div>

  <div class="content">
    <div class="title">
      <div class="wyf">物业费金额</div>
      <div class="je">￥{{billInfo.totalPrice}}</div>
    </div>

    <div class="detail">
      <div><span>计费单价：</span> ￥{{billInfo.price}}</div>
      <div><span>建筑面积：</span> {{billInfo.houseArea}} ㎡</div>
      <div><span>计费起始时间：</span> {{billInfo.costsStartTime}}</div>
      <div><span>计费终止时间：</span> {{billInfo.costsEndTime}}</div>
      <div><span>优惠金额：</span> ￥{{billInfo.discountAmount}}</div>
      <div *ngIf="this.pageType === 1"><span>支付时间：</span>{{billInfo.payDate | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
    </div>

    <div class="bottom">
      <div class="b-bar">历史账单记录</div>
      <div class="b-dump" (click)="goBack()"> ></div>
    </div>

  </div>


  <div class="footer" *ngIf="this.pageType !== 1">
    <div Button [type]="'primary'" (click)="onPay()">立即付款</div>
  </div>


</div>
