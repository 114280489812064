import {Component, OnInit} from '@angular/core';
import {API} from '../../api';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {PickerRef, PickerService} from 'ng-zorro-antd-mobile';
import {environment} from '../../../environments/environment';

declare var WeixinJSBridge;
declare var document: any;

@Component({
  selector: 'app-bill-list',
  templateUrl: './bill-list.component.html',
  styleUrls: ['./bill-list.component.less']
})
export class BillListComponent implements OnInit {
  pageTitle = '未缴账单';
  flag = true;
  index = 0;
  disabled = false;
  unpaidListData = [
    // {id: 1, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '690.2'},
    // {id: 2, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '660.2'},
    // {id: 3, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '660.2'},
    // {id: 4, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '690.2'},
    // {id: 5, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '6960.2'},
    // {id: 6, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '69.2'},
    // {id: 7, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '6960.2'},
    // {id: 8, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '6960.2'},
    // {id: 9, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '6960.2'},
    // {id: 10, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '6960.2'},
    // {id: 11, checked: false, costsStartTime: '2020-01-10 到 2021-01-10', totalPrice: '6960.2'},
  ];

  historyListData = [
    // {value: 0, date: '2020-01-10 到 2021-01-10', cost: '6960.2'},
  ];
  // 全选
  allCheckData = [{value: 0, name: '全选', checked: false}];
  singleArea = [
    // {id: 0, name: '东城区'},
    // {id: 1, name: '西城区'},
    // {id: 2, name: '崇文区'},
  ];
  name = '';
  value = [];
  height;
  // 支付账单个数
  costNum = 0;
  // 支付总金额
  costCount = 0;
  // 房间id
  houseId;
  // 账单id
  id = [];
  tabTwoIndex = 0;
  houseInfo;

  constructor(
    private api: API,
    public http: HttpClient,
    private router: Router,
    private picker: PickerService,
    private route: ActivatedRoute,
    private $activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.houseInfo = window.localStorage.getItem('houseInfo');
    if (this.houseInfo) {
      this.name = this.houseInfo.split(',')[1];
      this.houseId = this.houseInfo.split(',')[0];
    }
    // this.egg();
    this.$activatedRoute.queryParams.subscribe(queryParams => {
      if (queryParams.tab) {
        queryParams.tab === 'unpaid' ? this.index = 0 : this.index = 1;
      }
    });
    this.initSet();
    this.getHouseInfo();
  }

  onPageChange(ev: number): void {
    this.tabTwoIndex = ev;
    if (this.index === 0) {
      this.getUnpaidList(this.houseId);
    } else {
      this.getHistoryList(this.houseId);
    }
  }


  /**
   * 选中账单
   */
  countSelectBill(): void {
    let i = 0;
    this.costCount = 0;
    this.id = [];
    this.unpaidListData.forEach(item => {
      if (item.checked === true) {
        let costCount;
        i++;
        this.tabTwoIndex === 0 ? costCount = (this.costCount + item.totalPrice).toFixed(2) : costCount = (this.costCount + item.unpaidAmount).toFixed(2);
        this.costCount = Number(costCount);
        this.id.push(item.id);
      }
    });
    this.costNum = i;
  }


  /**
   * 初始化设置
   */
  initSet(): void {
    const uaHeight = window.outerHeight - (window.outerHeight - window.innerHeight);
    window.document.getElementById('billList').style.height = uaHeight + 'px';
    if (this.index === 0) {
      window.document.getElementById('list').style.height = uaHeight - 200 + 'px';
    } else {
      window.document.getElementById('list').style.height = uaHeight - 130 + 'px';
    }
  }

  /**
   * TODO 登录请求获取token
   */
  egg(): any {
    const param = {phone: '15598698589', openid: '1', residentId: 327};
    this.http.post(this.api.LOGIN, param).subscribe(e => {
      const data = (e as any).data;
      const token = window.localStorage.getItem('official_token');
      console.log(token);
    });
  }

  /**
   * 获取房产信息
   */
  getHouseInfo(): any {
    this.singleArea = [];
    this.http.get(this.api.GET_HOUSE).subscribe(e => {
      const data = (e as any).data;
      console.log(data);
      if (data.length > 0) {
        data.forEach(item => {
          this.singleArea.push({id: item.houseId, name: item.communityName + item.address});
        });
        console.log(this.singleArea);

        this.name === '' ? this.name = this.singleArea[0].name : '';
        !this.houseId ? this.houseId = this.singleArea[0].id : '';
        this.houseId ? this.getHistoryList(this.houseId) : '';
        this.houseId ? this.getUnpaidList(this.houseId) : '';
      }
    });
  }

  /**
   * 获取未缴费列表
   */
  getUnpaidList(houseId): any {
    this.unpaidListData = [];
    // 刷新未缴费账单 支付金额和选中支付清0
    this.costCount = 0;
    this.costNum = 0;
    this.allCheckData[0].checked = false;
    let apiPar;
    this.tabTwoIndex === 0 ? apiPar = this.api.PAY_LIST(houseId) : apiPar = this.api.getUnpaidBillList(houseId);
    this.http.get(apiPar).subscribe(e => {
      const data = (e as any).data;
      data.forEach(item => {
        if (this.tabTwoIndex === 0) {
          item.costsEndTime = item.costsEndTime.slice(0, 10);
          item.costsStartTime = item.costsStartTime.slice(0, 10);
        } else {
          item.meterReadTime = item.meterReadTime.slice(0, 10);
        }
        item.checked = false;
        this.unpaidListData.push(item);
      });

    });
  }

  /**
   * 获取历史缴费列表
   */
  getHistoryList(houseId): any {
    this.historyListData = [];
    let apiPar;
    if (this.tabTwoIndex === 0) {
      apiPar = this.api.getAllPaidBillByHouseId(houseId);
    } else if (this.tabTwoIndex === 1) {
      apiPar = this.api.PAY_HISTORY_LIST(houseId);
    } else {
      apiPar = this.api.getPaidBillList(houseId);
    }
    this.http.get(apiPar).subscribe(e => {
      const data = (e as any).data;
      data.forEach(item => {
        if (this.tabTwoIndex === 0) {
          item.startTime ? item.startTime = item.startTime.slice(0, 10) : null;
          item.endTime ? item.endTime = item.endTime.slice(0, 10) : null;
        } else if (this.tabTwoIndex === 1) {
          item.costsEndTime = item.costsEndTime.slice(0, 10);
          item.costsStartTime = item.costsStartTime.slice(0, 10);
        } else {
          item.meterReadTime = item.meterReadTime.slice(0, 10);
        }
        this.historyListData.push(item);
      });
      console.log(this.historyListData);
    });

  }

  goBack(): void {
    this.router.navigate(['/list']);
  }

  /**
   * 单个选择
   */
  onChange(ev): any {
    // console.log('单个选择', ev);==
    this.countSelectBill();
    // 只能选一个支付情况
    // this.id = item.id;
    // if (item.checked) {
    //   this.unpaidListData.forEach(item => {
    //     // item.checked = item.id === this.id;
    //     if (item.id === this.id) {
    //       item.checked = true;
    //       this.costCount = Number(item.totalPrice);
    //     } else {
    //       item.checked = false;
    //     }
    //   });
    // } else {
    //   this.costCount = 0;
    // }
    // this.costCount = this.id.length;
  }

  /**
   * 全选
   */
  onAllChange(ev): any {
    console.log('全选', ev);
    if (ev.checked) {
      this.unpaidListData.forEach(item => {
        item.checked = true;
      });
    } else {
      this.unpaidListData.forEach(item => {
        item.checked = false;
      });
    }
    this.countSelectBill();
  }

  /**
   * tab页选择
   */
  onTabClick(item): any {
    this.tabTwoIndex = 0;
    this.index = item.index;
    if (this.index === 0) {
      this.pageTitle = '未缴账单';
      window.document.getElementById('list').style.height = window.screen.height - 180 + 'px';
      this.houseId ? this.getUnpaidList(this.houseId) : '';
    } else {
      this.pageTitle = '历史账单';
      window.document.getElementById('list').style.height = window.screen.height - 110 + 'px';
      this.houseId ? this.getHistoryList(this.houseId) : '';
    }
    this.initSet();
  }


  onClick(ev, billType): any {
    // const id = ev.id;
    // 跳转详情
    switch (this.index) {
      case 0:
        if (this.tabTwoIndex === 0) {
          this.router.navigate(['/detail'], {
            queryParams: {
              houseId: this.houseId,
              id: ev.id,
            }
          });
        } else {
          this.router.navigate(['/water-detail'], {
            queryParams: {
              id: ev.id,
              bType: billType,
              type: ev.billType,
            }
          });
        }
        break;
      case 1:
        if (this.tabTwoIndex === 0) {
          if (ev.billType === 1) {
            this.router.navigate(['/detail'], {
              queryParams: {
                houseId: this.houseId,
                id: ev.id,
              }
            });
          } else {
            this.router.navigate(['/water-detail'], {
              queryParams: {
                id: ev.id,
                bType: billType,
                type: ev.billType,
              }
            });
          }
        } else if (this.tabTwoIndex === 1) {
          this.router.navigate(['/detail'], {
            queryParams: {
              houseId: this.houseId,
              id: ev.id,
            }
          });
        } else {
          this.router.navigate(['/water-detail'], {
            queryParams: {
              id: ev.id,
              bType: billType,
            }
          });
        }
        break;
      default:
        break;
    }
  }

  showPicker(): any {
    const data = [];
    this.singleArea.forEach(item => {
      data.push(item.name);
    });
    const ref: PickerRef = this.picker.showPicker({value: this.value, data: data}, result => {
        console.log(result);
        this.costNum = 0;
        this.costCount = 0;
        this.allCheckData[0].checked = false;
        this.name = this.getResult(result);
        this.value = this.getValue(result);
        this.singleArea.forEach(item => {
          if (result[0] === item.name) {
            this.houseId = item.id;
            this.getUnpaidList(this.houseId);
            this.getHistoryList(this.houseId);
          }
        });
        window.localStorage.setItem('houseInfo', this.houseId + ',' + this.name);
      },
      cancel => {
        console.log('cancel');
      }
    );
  }

  getResult(result): any {
    this.value = [];
    let temp = '';
    result.forEach(item => {
      this.value.push(item.label || item);
      temp += item.label || item;
    });
    return this.value.map(v => v).join(',');
  }

  getValue(result): any {
    const value = [];
    let temp = '';
    result.forEach(item => {
      value.push(item.value || item);
      temp += item.value || item;
    });
    return value;
  }

  /**
   * 支付请求
   */
  onPay(): any {
    if (this.id.length > 0) {
      const id = this.id.join(',');
      if (this.tabTwoIndex === 0) {
        this.http.get(this.api.GET_PAY_INFO(id)).subscribe(e => {
          const data = (e as any).data;
          // 账单金额为0不走微信支付
          if (data.amount === 0) {
            this.getUnpaidList(this.houseId);
          } else {
            if (typeof WeixinJSBridge === 'undefined') {
              if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(data), false);
              } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(data));
                document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(data));
              }
            } else {
              this.onBridgeReady(data);
            }
          }
        });
      } else {
        this.http.get(this.api.getPayBillInfo(id)).subscribe(e => {
          const data = (e as any).data;
          // 账单金额为0不走微信支付
          if (data.amount === 0) {
            this.getUnpaidList(this.houseId);
          } else {
            if (typeof WeixinJSBridge === 'undefined') {
              if (document.addEventListener) {
                document.addEventListener('WeixinJSBridgeReady', this.onBridgeReady(data), false);
              } else if (document.attachEvent) {
                document.attachEvent('WeixinJSBridgeReady', this.onBridgeReady(data));
                document.attachEvent('onWeixinJSBridgeReady', this.onBridgeReady(data));
              }
            } else {
              this.onBridgeReady(data);
            }
          }
        });
      }

    } else {
      alert('请选择账单支付');
    }

  }

  onBridgeReady(data): any {
    WeixinJSBridge.invoke(
      'getBrandWCPayRequest', {
        'appId': data.appId,     // 公众号名称，由商户传入
        'timeStamp': data.timeStamp,         // 时间戳，自1970年以来的秒数
        'nonceStr': data.nonceStr, // 随机串
        'package': data.package,
        'signType': data.signType,        // 微信签名方式：
        'paySign': data.paySign, // 微信签名
      }, (res) => {
        this.http.get(this.api.PAY_SUCCESS(this.id[0])).subscribe(e => {
          // window.location.href = environment.host + '/list';
        });
        if (res.err_msg == 'get_brand_wcpay_request:ok') {
          alert('微信支付成功!');
        } else if (res.err_msg == 'get_brand_wcpay_request:cancel') {
          alert('用户取消支付!');
        } else {
          alert('支付失败!');
        }
      });
  }

  /**
   * 跳转到所有历史缴费记录
   */
  onDumpHistory(): any {
    this.router.navigate(['/history-list']).then();
  }


  /**
   * 跳转充值
   */
  onDumpRecharge(): any {
    this.router.navigate(['/recharge'], {
      queryParams: {
        houseId: this.houseId,
        houseName: this.name,
      }
    });
  }

}
