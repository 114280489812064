<div class="history-list">
  <div class="nav-bar">缴费记录
    <span class="nav-dump" (click)="goBack()"><img  style="width: 20px;" src="assets/back.png" alt="返回"></span>
  </div>

  <div class="tab-two">
    <div class="tab-two">
      <div [class]="tabTwoIndex==0?'tab-two-block-active':'tab-two-block'" style="width: 50%" (click)="onPageChange(0)">全部</div>
      <div [class]="tabTwoIndex==1?'tab-two-block-active':'tab-two-block'" style="width: 50%" (click)="onPageChange(1)">物业费</div>
      <div [class]="tabTwoIndex==2?'tab-two-block-active':'tab-two-block'" style="width: 50%" (click)="onPageChange(2)">水费</div>
    </div>
  </div>

  <List>
    <div class="list-box" *ngFor="let i of historyListData">
      <div class="b-space"></div>
      <ListItem *ngIf="tabTwoIndex==0" style="height: 65px;" [extra]="'￥'+i.amount" [arrow]="'horizontal'"
                (onClick)="onClick(i,'record')">
        <div *ngIf="i.billType == 1" class="wyf">物业费</div>
        <div *ngIf="i.billType == 1" class="wye-date">{{i.startTime}} 到 {{i.endTime}}</div>

        <div *ngIf="i.billType == 2" class="wyf">水费</div>
        <div *ngIf="i.billType == 2" class="wye-date">{{i.startTime}}</div>
      </ListItem>

      <ListItem *ngIf="tabTwoIndex==1" style="height: 65px;" [extra]="'￥'+i.totalPrice" [arrow]="'horizontal'"
                (onClick)="onClick(i,'record')">
        <div class="wyf">物业费</div>
        <div class="wye-date">{{i.costsStartTime}} 到 {{i.costsEndTime}}</div>
      </ListItem>

      <ListItem *ngIf="tabTwoIndex==2" style="height: 65px;" [extra]="'￥'+i.billAmount" [arrow]="'horizontal'"
                (onClick)="onClick(i,'record')">
        <div class="wyf">水费</div>
        <div class="wye-date">{{i.meterReadTime}}</div>
      </ListItem>
    </div>
  </List>
</div>
