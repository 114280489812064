import {Injectable} from '@angular/core';
import {environment} from '../environments/environment';

@Injectable({providedIn: 'root'})
export class API {
  public WBE_HOST = environment.webHost;

  public HOST = environment.host + environment.path;

  public ICONFONT_HOST = environment.iconfont;

  /**
   * 获取房产信息
   */
  public GET_HOUSE = `${this.HOST}/residentAudit/getHouse`;

  /**
   * 未缴账单
   */
  public PAY_LIST = id => `${this.HOST}/pub/payList?houseId=${id}`;

  /**
   * 历史账单(根据房间号获取)
   */
  public PAY_HISTORY_LIST = id => `${this.HOST}/pub/payHistoryListByHouseId?houseId=${id}`;

  /**
   * 账单详情
   */
  public PAY_COSTS_DETAIL = `${this.HOST}/pub/getCostsInfoById`;

  /**
   * 全部历史账单
   */
  public HISTORY_LIST = `${this.HOST}/pub/payHistoryListByPayer`;

  /**
   * 登录请求
   */
  public PUB_AUTH = `${this.HOST}/pub/pubAuth`;

  /**
   * 付款请求
   */
  public GET_PAY_INFO = id => `${this.HOST}/pub/getPayInfo?costsIds=${id}`;

  /**
   * 付款成功请求
   */
  public PAY_SUCCESS = id => `${this.HOST}/pub/flushResult?costsId=${id}`;

  /**
   * 登录请求 todo
   */
  public LOGIN = `${this.HOST}/pub/login`;


  /**
   * 水费未支付账单列表
   */
  public getUnpaidBillList = id => `${this.HOST}/pub/getUnpaidBillList?houseId=${id}`;

  // 全部缴费历史账单
  public getAllPaidBillByPayer = `${this.HOST}/pub/getAllPaidBillByPayer`;

  // 水费历史账单
  public getPaidBillList = id => `${this.HOST}/pub/getPaidBillList?houseId=${id}`;

  // 获取房间所有缴费账单
  public getAllPaidBillByHouseId = id => `${this.HOST}/pub/getAllPaidBillByHouseId?houseId=${id}`;

  // 水费缴费历史
  public getPaidBillByPayer = `${this.HOST}/pub/getPaidBillByPayer`;

  // 未缴水费详情
  public getUnpaidBillInfoByBillId = id => `${this.HOST}/pub/getUnpaidBillInfoByBillId?id=${id}`;

  // 已缴账单查看详情
  public getPaidBillInfoByBillId = id => `${this.HOST}/pub/getPaidBillInfoByBillId?id=${id}`;

  // 水费充值
  public pubRechargeAmount = (houseId, amount) => `${this.HOST}/pub/pubRechargeAmount?houseId=${houseId}&amount=${amount}`;

  // 账单公众号付款
  public getPayBillInfo = (ids) => `${this.HOST}/pub/getPayBillInfo?costsIds=${ids}`;
}
