<div id="detailPage" class="detail">
  <div class="nav-bar">
    {{pageTitle}}
    <span class="nav-dump" (click)="goBack()"><img style="width: 20px;" src="assets/back.png" alt="返回"></span>
  </div>

  <div class="up" *ngIf="billType !== 'record'">
    <span class="house">房产信息：</span> <span
    class="house-content">{{billInfo.houseInfo}} </span>
  </div>

  <!--  未缴水费详情-->
  <div class="content-unpaid">
    <div class="content" *ngIf="billType === 'unpaid'">
      <div class="title">
        <div class="wyf">未缴水费金额</div>
        <div class="je">￥{{billInfo.unpaidAmount}}</div>
      </div>
      <div class="detail">
        <div><span>计费单价：</span> ￥{{billInfo.price}}</div>
        <div><span>起码吨数：</span> {{billInfo.startNum}}</div>
        <div><span>止码吨数：</span> {{billInfo.endNum}}</div>
        <div><span>本期吨数：</span> {{billInfo.totalNum}}</div>
        <div><span>起码时间：</span> {{billInfo.startTime}}</div>
        <div><span>抄表时间：</span> {{billInfo.meterReadTime}}</div>
        <div><span>优惠金额：</span> ￥{{billInfo.discountAmount}}</div>
        <div><span>已缴金额：</span> ￥{{billInfo.paidAmount}}</div>
        <div><span>应缴金额：</span> ￥{{billInfo.billAmount}}</div>
      </div>
      <div class="bottom">
        <div class="b-bar">{{bottomPageTitle}}</div>
        <div class="b-dump" (click)="goBack()"> ></div>
      </div>
    </div>
  </div>


  <!--  历史水费详情-->
  <div class="content" *ngIf="billType === 'history'">
    <div class="title">
      <div class="wyf">水费金额</div>
      <div class="je">￥{{billInfo.realCost}}</div>
    </div>
    <div class="detail">
      <div><span>计费单价：</span> ￥{{billInfo.price}}</div>
      <div><span>起码吨数：</span> {{billInfo.startNum}}</div>
      <div><span>止码吨数：</span> {{billInfo.endNum}}</div>
      <div><span>本期吨数：</span> {{billInfo.totalNum}}</div>
      <div><span>起码时间：</span> {{billInfo.startTime}}</div>
      <div><span>抄表时间：</span> {{billInfo.meterReadTime}}</div>
      <div><span>优惠金额：</span> ￥{{billInfo.discountAmount}}</div>
      <div><span>划扣金额：</span> ￥{{billInfo.consumeAmount}}</div>
      <div><span>支付金额：</span> ￥{{billInfo.payAmount}}</div>
      <div><span>支付时间：</span>{{billInfo.payTime | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
    </div>
    <div class="bottom">
      <div class="b-bar">{{bottomPageTitle}}</div>
      <div class="b-dump" (click)="goBack()"> ></div>
    </div>
  </div>

  <!--  缴费水费详情-->
  <div class="content" *ngIf="billType === 'record'"  style="margin-top: 20px;">
    <div class="title">
      <div class="wyf">支付金额</div>
      <div class="je">￥{{billType === 'unpaid' ? billInfo.billAmount : billInfo.payAmount}}</div>
    </div>
    <div class="detail">
      <div><span>房产信息：</span> {{billInfo.houseName}}</div>
      <div><span>费用类型：</span> 水费</div>
      <div><span>优惠金额：</span> ￥{{billInfo.discountAmount}}</div>
      <div><span>应缴金额：</span>￥{{billInfo.shouldCost}}</div>
      <div><span>划扣金额：</span> ￥{{billInfo.consumeAmount}}</div>
      <div><span>支付时间：</span>{{billInfo.payTime | date: 'yyyy-MM-dd HH:mm:ss'}}</div>
    </div>
    <div class="bottom">
      <div class="b-bar">{{bottomPageTitle}}</div>
      <div class="b-dump" (click)="goBack()"> ></div>
    </div>
  </div>


  <div class="footer" *ngIf="billType === 'unpaid'">
    <div Button [type]="'primary'" (click)="onPay()">立即付款</div>
  </div>


</div>
