import {Component, OnInit} from '@angular/core';
import {API} from '../../api';
import {HttpClient} from '@angular/common/http';
import {ActivatedRoute, Params, Router} from '@angular/router';

@Component({
  selector: 'app-public-page-two',
  templateUrl: './public-page-two.component.html',
  styleUrls: ['./public-page-two.component.less']
})
export class PublicPageTwoComponent implements OnInit {

  constructor(
    private api: API,
    public http: HttpClient,
    private router: Router,
    private $activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.$activatedRoute.queryParams.subscribe((params: Params) => {
      const token = params.token;
      // 存在code,请求后端接口
      if (token && token.length > 0) {
        window.localStorage.setItem('official_token', token);
        this.router.navigate(['/list']);
      } else {
        alert('请在小程序实名认证后使用');
      }
    });
  }
}
